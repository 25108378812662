import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../store";
import { IReportLinks, IInstance, IReport } from "../common/types";
import { useNavigate, useParams } from "react-router-dom";

import * as Routes from "../routes";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
const AdminReportCreate = () => {
  const { state } = useContext(StoreContext);
  const navigate = useNavigate();
  const params = useParams();
  const [instances, setInstances] = useState<IInstance[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);
  const [report, setReport] = useState<ReportFormBody>({
    instanceID: "",
    organisationID: "",
    reportLinks: [],
  });
  const [newReportLink, setNewReportLink] = useState<IReportLinks>({
    title: "",
    link: "",
  });

  useEffect(() => {
    if (state.user && params.id) {
      setFetching(true);
      const url = `${process.env.REACT_APP_BASE_URL}/v1/report`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user!.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setFetching(false);
          const reports = res.filter((report: IReport) => {
            if (Number(report.id) === Number(params.id)) {
              return report;
            }
          });
          if (reports && reports.length > 0) {
            const rep = reports[0];
            console.log(rep);
            setReport({
              instanceID: rep.instanceID,
              organisationID: rep.organisationID,
              reportLinks: rep.reportLinks,
            });
          }
        });
    }
  }, [state.user]);

  useEffect(() => {
    if (state.user) {
      const url = `${process.env.REACT_APP_BASE_URL}/v1/instance`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user!.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setInstances(res);
        });
    }
  }, [state.user]);

  const selectChange = (event: SelectChangeEvent) => {
    const instanceID = event.target.value;
    const instance = instances.find((instance) => {
      if (Number(instanceID) === Number(instance.id)) {
        return instance;
      }
    });
    if (instance) {
      setReport({
        ...report,
        instanceID: String(instance.id),
        organisationID: String(instance.organisationID),
      });
    }
  };

  const handleNewReportLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewReportLink({
      ...newReportLink,
      [event.target.name]: event.target.value,
    });
  };

  const editReportLink = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const editedReportLinks = report.reportLinks.map(
      (link, i): IReportLinks => {
        if (i === index) {
          return {
            ...link,
            [event.target.name]: event.target.value,
          };
        }
        return link;
      }
    );
    setReport({
      ...report,
      reportLinks: editedReportLinks,
    });
  };

  const handleReportLinkDelete = (index: number) => {
    const filteredReportLinks = report.reportLinks.filter((link, i) => {
      if (i !== index) {
        return link;
      }
    });
    setReport({
      ...report,
      reportLinks: filteredReportLinks,
    });
  };

  const handleAddNewReportLink = () => {
    setReport({
      ...report,
      reportLinks: report.reportLinks.concat(newReportLink),
    });
    setNewReportLink({
      title: "",
      link: "",
    });
  };

  const submit = () => {
    const url = params.id
      ? `${process.env.REACT_APP_BASE_URL}/v1/report/${params.id}`
      : `${process.env.REACT_APP_BASE_URL}/v1/report`;
    const body = JSON.stringify({
      report: {
        instanceID: Number(report.instanceID),
        organisationID: Number(report.organisationID),
        reportLinks: report.reportLinks,
      },
    });
    console.log(body);
    fetch(url, {
      method: params.id ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user!.token}`,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        setErrors([String(res.status)]);
      })
      .then((res) => {
        navigate(Routes.ADMIN);
      });
  };

  return (
    <>
      {fetching ? (
        <>Loading...</>
      ) : (
        <Paper variant="outlined" sx={{ p:{ xs: 2, md: 4 }}}>
          <Stack spacing={3}>
          <Typography variant="h4" component="h1">
          {params.id ? "Update" : "New"} Report
        </Typography>
            {params.id ? (
                      <Typography variant="body1" component="h1">
                   
             
                {
                  instances.find((instance) => {
                    if (Number(instance.id) === Number(report.instanceID)) {
                      return instance;
                    }
                  })?.name
                }
                </Typography>
            ) : (
              <FormControl fullWidth>
                <InputLabel id="instance-select">Instance</InputLabel>
                <Select
                  labelId="instance-select"
                  id="instance-select"
                  label="Instance"
                  onChange={selectChange}
                  value={report.instanceID}
                >
                  {instances.map((instance) => {
                    return (
                      <MenuItem value={instance.id}>{instance.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
  <Divider />
            {report.reportLinks.map((link, index) => {
              return (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} >
                  <TextField
                    fullWidth
                    
                    label="Title"
                    id="title"
                    defaultValue="title"
                    name="title"
                    value={report.reportLinks[index].title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      editReportLink(event, index)
                    }
                  />
                  <TextField
                    fullWidth
                    label="Link"
                    id="link"
                    defaultValue="link"
                    name="link"
                    value={report.reportLinks[index].link}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      editReportLink(event, index)
                    }
                  />
                                <Button          color="error" onClick={() => handleReportLinkDelete(index)} variant="outlined">
                                <DeleteIcon />
              </Button>

                </Stack>
              );
            })}
            <Stack  direction={{ xs: 'column', md: 'row'}} spacing={1}>
              <TextField
                fullWidth
                label="Title"
                id="title"
                defaultValue="Title"
                name="title"
                value={newReportLink.title}
                onChange={handleNewReportLink}
              />
              <TextField
                fullWidth
                label="Link"
                id="link"
                defaultValue="link"
                name="link"
                value={newReportLink.link}
                onChange={handleNewReportLink}
              />
              <Button onClick={handleAddNewReportLink} variant="outlined">
                <AddCircleIcon />
              </Button>
            </Stack>

            <Button
              
              onClick={submit}
              variant="contained"
              size="large"
            >
              {" "}
              {params.id ? "Update" : "New"} Report
            </Button>
          </Stack>
        </Paper>
      )}
    </>
  );
};

interface ReportFormBody {
  id?: string;
  instanceID: string;
  organisationID: string;
  reportLinks: IReportLinks[];
}

export default AdminReportCreate;
